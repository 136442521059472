<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="type1"
    width="1150px"
    destroy-on-close
  >
    <el-scrollbar :maxHeight="contentStyleObj">
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="info"
        size="small"
        label-position="right"
        :inline="true"
        label-width="140px"
      >
        <div class="common_dialog_box clearfix">
          <div class="common_title record">基本信息</div>
          <div class="item_one clearfix">
            <el-form-item label="姓名:" prop="name">
              <el-input v-model="info.name" placeholder="请输入姓名" />
            </el-form-item>
            <el-form-item label="证件类型:" prop="iDType">
              <el-select
                v-model="info.iDType"
                filterable
                placeholder="请选择证件类型"
                @change="idTypeChange"
              >
                <el-option
                  v-for="itemb in iDTypes"
                  :key="itemb.id"
                  :label="itemb.label"
                  :value="itemb.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号码:" prop="iDNo">
              <el-input
                @change="idnoChange"
                v-model="info.iDNo"
                placeholder="请输入证件号"
              />
            </el-form-item>
            <el-form-item label="国籍(地区):" prop="country">
              <el-select
                v-model="info.country"
                filterable
                placeholder="请选择国籍"
              >
                <el-option
                  v-for="item in countryOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="其他证件类型:"
              prop="qtIdType"
              v-if="from == 'oversea'"
              :rules="
                [
                  '中华人民共和国港澳居民居住证',
                  '中华人民共和国台湾居民居住证',
                  '外国人永久居留身份证（外国人永久居留证）',
                ].includes(info.iDType)
                  ? [
                      {
                        required: true,
                        message: '请选择其他证件类型',
                        trigger: 'change',
                      },
                    ]
                  : []
              "
            >
              <el-select
                v-model="info.qtIdType"
                filterable
                placeholder="请选择其他证件类型"
                clearable
              >
                <el-option
                  v-for="item in qtIdTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="from == 'oversea'"
              label="其他证件号码:"
              prop="qtIdno"
              :rules="
                [
                  '中华人民共和国港澳居民居住证',
                  '中华人民共和国台湾居民居住证',
                  '外国人永久居留身份证（外国人永久居留证）',
                ].includes(info.iDType)
                  ? [
                      {
                        required: true,
                        message: '请输入其他证件号码',
                        trigger: 'blur',
                      },
                    ]
                  : []
              "
            >
              <el-input
                v-model="info.qtIdno"
                placeholder="请输入其他证件号"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="出生国家(地区):"
              prop="csgj"
              v-if="from == 'oversea'"
            >
              <el-select
                v-model="info.csgj"
                filterable
                placeholder="请选择出生国家"
              >
                <el-option
                  v-for="item in csCountryOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="中文名:"
              prop="chineseName"
              v-if="from == 'oversea'"
            >
              <el-input
                v-model="info.chineseName"
                placeholder="请输入中文名"
                :disabled="
                  ![
                    '外国护照',
                    '外国人永久居留身份证（外国人永久居留证）',
                  ].includes(info.iDType)
                "
              />
            </el-form-item>
            <el-form-item label="性别:" prop="sex">
              <el-select v-model="info.sex" filterable placeholder="请选择性别">
                <el-option
                  v-for="item in sex"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号码:" v-if="info.isEmployee == 2">
              <el-input v-model="info.tel" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item label="手机号码:" prop="tel" v-else>
              <el-input v-model="info.tel" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item label="出生日期:" prop="birthday">
              <el-date-picker
                v-model="info.birthday"
                value-format="YYYY-MM-DD"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="人员状态:" prop="status">
              <el-select
                v-model="info.status"
                filterable
                placeholder="请选择人员状态"
              >
                <el-option label="正常" :value="1"></el-option>
                <el-option label="非正常" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="按6万扣除:" prop="jcStatus">
              <el-select
                v-model="info.jcStatus"
                filterable
                placeholder="请选择是否按6万扣除"
              >
                <el-option
                  v-for="itemb in jcOptions"
                  :key="itemb.value"
                  :label="itemb.label"
                  :value="itemb.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否残疾:">
              <el-select
                v-model="info.disability"
                filterable
                placeholder="请选择是否残疾"
              >
                <el-option
                  v-for="itemb in options"
                  :key="itemb.value"
                  :label="itemb.label"
                  :value="itemb.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="残疾证件类型:">
              <el-select
                v-model="info.disabilityIdType"
                filterable
                placeholder="请选择残疾证件类型"
                :disabled="info.disability != 1"
              >
                <el-option
                  v-for="item in disabledOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="残疾证号:">
              <el-input
                v-model="info.disabilityNo"
                :disabled="info.disability != 1"
              />
            </el-form-item>
            <el-form-item label="是否烈属:">
              <el-select
                v-model="info.martyred"
                filterable
                placeholder="请选择是否烈属"
              >
                <el-option
                  v-for="itemb in options"
                  :key="itemb.value"
                  :label="itemb.label"
                  :value="itemb.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="烈属证号:">
              <el-input
                v-model="info.martyredNo"
                :disabled="info.martyred != 1"
              />
            </el-form-item>
            <el-form-item label="是否孤老:">
              <el-select v-model="info.elderly" filterable>
                <el-option
                  v-for="itemb in options"
                  :key="itemb.value"
                  :label="itemb.label"
                  :value="itemb.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input v-model="info.remark" placeholder="请输入备注" />
            </el-form-item>
            <el-form-item label="减免费用为0:">
              <el-select v-model="info.gsjm0" placeholder="请选择">
                <el-option
                  v-for="item in gsjmOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-tooltip placement="top">
                <template #content>
                  <div>
                    <p>当选择个税减免为0，则生成的工资表累计数据减免为0；</p>
                    <p>当选择经营所得减免为0，则生成的经营所得减免为0；</p>
                    <p>当选择全部减免为0，则个税和经营所得减免都为0；</p>
                  </div>
                </template>
                <i
                  class="iconfont icon-wenhao"
                  style="
                    color: red;
                    cursor: pointer;
                    font-size: 13px;
                    position: absolute;
                    right: -20px;
                  "
                ></i>
              </el-tooltip>
            </el-form-item>
          </div>
        </div>
        <div class="common_dialog_box clearfix">
          <div class="common_title record">任职受雇信息和出入境信息</div>
          <div class="item_one clearfix">
            <el-form-item label="是否雇员:" prop="isEmployee">
              <el-select v-model="info.isEmployee" filterable>
                <el-option
                  v-for="itemb in options"
                  :key="itemb.value"
                  :label="itemb.label"
                  :value="itemb.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否股东、投资者:">
              <el-select v-model="info.stockholder" filterable>
                <el-option
                  v-for="itemb in options"
                  :key="itemb.value"
                  :label="itemb.label"
                  :value="itemb.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司股本总额:">
              <el-input
                type="number"
                v-model.number="info.totalStock"
                :disabled="info.stockholder != 1"
              />
            </el-form-item>
            <el-form-item label="个人股本(投资)额:">
              <el-input
                type="number"
                v-model.number="info.personalStock"
                :disabled="info.stockholder != 1"
              />
            </el-form-item>
            <el-form-item label="分配比例（%）:">
              <el-input
                type="number"
                v-model.number="info.stockPercent"
                :disabled="info.stockholder != 1"
              />
            </el-form-item>
            <el-form-item label="自然人登录密码:">
              <el-input
                v-model="info.personLoginPwd"
                :disabled="info.stockholder != 1"
              />
            </el-form-item>
            <el-form-item
              label="受雇日期:"
              prop="joinDate"
              :rules="
                info.isEmployee == 1
                  ? [
                      {
                        required: true,
                        message: '请选择受雇日期',
                        trigger: 'change',
                      },
                    ]
                  : []
              "
            >
              <el-date-picker
                v-model="info.joinDate"
                type="date"
                placeholder="选择日期"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="离职日期:"
              prop="departureDate"
              v-if="info.status === 2"
            >
              <el-date-picker
                v-model="info.departureDate"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="首次入境时间:"
              prop="scyjsj"
              v-if="from == 'oversea'"
            >
              <el-date-picker
                v-model="info.scyjsj"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="预计离境时间:"
              prop="yjljsj"
              v-if="from == 'oversea'"
            >
              <el-date-picker
                v-model="info.yjljsj"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="涉税事由:"
              prop="sssy"
              v-if="from == 'oversea'"
            >
              <el-select
                v-model="info.sssy"
                filterable
                placeholder="请选择涉税事由"
              >
                <el-option
                  v-for="item in ssOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="common_dialog_box clearfix">
          <div class="common_title record">专项附加扣除</div>
          <div class="item_one clearfix">
            <div style="display: flex">
              <el-form-item label="子女教育:">
                <el-input
                  type="number"
                  v-model.number="info.childrenEducation"
                  style="width: 200px"
                  placeholder="请输入子女教育"
                />
                <el-date-picker
                  v-model="info.time1"
                  type="date"
                  placeholder="初次使用日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
                <el-date-picker
                  v-model="info.timeEnd1"
                  type="date"
                  placeholder="终止日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="继续教育:">
                <el-input
                  type="number"
                  v-model.number="info.continuingEducation"
                  style="width: 200px"
                  placeholder="请输入继续教育"
                />
                <el-date-picker
                  v-model="info.time2"
                  type="date"
                  placeholder="初次使用日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
                <el-date-picker
                  v-model="info.timeEnd2"
                  type="date"
                  placeholder="终止日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="住房贷款利息:">
                <el-input
                  type="number"
                  v-model.number="info.housingLoan"
                  style="width: 200px"
                  placeholder="请输入住房贷款利息"
                />
                <el-date-picker
                  v-model="info.time4"
                  type="date"
                  placeholder="初次使用日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
                <el-date-picker
                  v-model="info.timeEnd4"
                  type="date"
                  placeholder="终止日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="住房租金:">
                <el-input
                  type="number"
                  v-model.number="info.housingRent"
                  style="width: 200px"
                  placeholder="请输入住房租金"
                />
                <el-date-picker
                  v-model="info.time5"
                  type="date"
                  placeholder="初次使用日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
                <el-date-picker
                  v-model="info.timeEnd5"
                  type="date"
                  placeholder="终止日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="赡养老人:">
                <el-input
                  type="number"
                  v-model.number="info.support"
                  style="width: 200px"
                  placeholder="请输入赡养老人"
                />
                <el-date-picker
                  v-model="info.time6"
                  type="date"
                  placeholder="初次使用日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
                <el-date-picker
                  v-model="info.timeEnd6"
                  type="date"
                  placeholder="终止日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="婴幼儿照护费用:">
                <el-input
                  type="number"
                  v-model.number="info.babyReduction"
                  style="width: 200px"
                  placeholder="请输入婴幼儿照护费用"
                />
                <el-date-picker
                  v-model="info.time7"
                  type="date"
                  placeholder="初次使用日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
                <el-date-picker
                  v-model="info.timeEnd7"
                  type="date"
                  placeholder="终止日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="个人养老金:">
                <el-input
                  type="number"
                  v-model.number="info.personalPension"
                  style="width: 200px"
                  placeholder="个人养老金"
                />
                <el-date-picker
                  v-model="info.time8"
                  type="date"
                  placeholder="初次使用日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
                <el-date-picker
                  v-model="info.timeEnd8"
                  type="date"
                  placeholder="终止日期"
                  value-format="YYYY-MM-DD"
                  style="margin-left: 40px; width: 300px"
                ></el-date-picker>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </el-scrollbar>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="saveadd" size="small">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { saveEmployees } from "@/api/salary";
import { ref, getCurrentInstance, onMounted, computed } from "vue";
import {
  GS_REDUCTION_OPTION,
  CERTIFICATE_TYPE_OPTION,
  FOREIGN_PASSPORT_OPTION,
} from "@/utils/commonData.js";
const { proxy } = getCurrentInstance();
const emits = defineEmits();
const props = defineProps({
  type1: {
    type: String,
    default: "",
  },
  comId: {
    type: Number,
    default: 0,
  },
  period: {
    type: String,
    default: "",
  },
  from: {
    type: String,
    default: "inside",
  },
});
const info = ref({});
const dialogFormVisible = ref(false);
const disabledDate = (time) => {
  let date = props.period
    ? props.period
    : proxy.$store.getters["user/comInfo"].period;
  return time.getTime() > new Date(proxy.$getLastDay(date)).getTime();
};

const sex = [
  {
    value: 1,
    label: "男",
  },
  {
    value: 2,
    label: "女",
  },
];
const options = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 2,
    label: "否",
  },
];
const jcOptions = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 0,
    label: "否",
  },
];
const disabledOptions = [
  {
    label: "残疾证",
    value: "残疾证",
  },
  {
    label: "残疾军人证",
    value: "残疾军人证",
  },
  {
    label: "伤残人民警察证",
    value: "伤残人民警察证",
  },
  {
    label: "残疾消防救援人员证",
    value: "残疾消防救援人员证",
  },
  {
    label: "伤残预备役人员、伤残民兵民工证",
    value: "伤残预备役人员、伤残民兵民工证",
  },
  {
    label: "因公伤残人员证",
    value: "因公伤残人员证",
  },
];
const iDTypes = computed(() => {
  if (props.from == "oversea") {
    return CERTIFICATE_TYPE_OPTION;
  } else {
    return [{ label: "居民身份证", value: "居民身份证" }];
  }
});
const gsjmOptions = GS_REDUCTION_OPTION;
const rules = ref({
  name: [{ required: true, message: "姓名不能为空", trigger: "change" }],
  iDType: [{ required: true, message: "请选择证件类型", trigger: "change" }],
  iDNo: [{ required: true, message: "证件号码不能为空", trigger: "change" }],
  country: [{ required: true, message: "请选择国籍(地区)", trigger: "change" }],
  csgj: [
    { required: true, message: "请选择出生国家(地区)", trigger: "change" },
  ],
  sex: [{ required: true, message: "请选择性别", trigger: "change" }],
  tel: [{ required: true, message: "手机号码不能为空", trigger: "change" }],
  birthday: [
    { required: true, message: "出生日期不能为空", trigger: "change" },
  ],
  status: [{ required: true, message: "请选择人员状态", trigger: "change" }],
  jcStatus: [
    { required: true, message: "请选择是否6万扣除", trigger: "change" },
  ],
  isEmployee: [
    { required: true, message: "请选择是否雇员", trigger: "change" },
  ],
  departureDate: [
    { required: true, message: "请选择离职日期", trigger: "change" },
  ],
  scyjsj: [
    { required: true, message: "请选择首次入境时间", trigger: "change" },
  ],
  yjljsj: [
    { required: true, message: "请选择预计离境时间", trigger: "change" },
  ],
  sssy: [{ required: true, message: "请选择涉税是由", trigger: "change" }],
});
const contentStyleObj = ref({});
//国籍
const option1 = ref([{ label: "中国", value: "中国" }]);
const option2 = ref([
  { label: "中国香港", value: "中国香港" },
  { label: "中国澳门", value: "中国澳门" },
]);
const option3 = ref([{ label: "中国台湾", value: "中国台湾" }]);
const option4 = ref(FOREIGN_PASSPORT_OPTION);
const countryOption = computed(() => {
  let arr = [];
  if (props.from == "oversea") {
    if (
      ["港澳居民来往内地通行证", "中华人民共和国港澳居民居住证"].includes(
        info.value.iDType
      )
    ) {
      arr = option2.value;
    } else if (info.value.iDType == "中国护照") {
      arr = option1.value;
    } else if (
      ["台湾居民来往大陆通行证", "中华人民共和国台湾居民居住证"].includes(
        info.value.iDType
      )
    ) {
      arr = option3.value;
    } else if (
      ["外国护照", "外国人永久居留身份证（外国人永久居留证）"].includes(
        info.value.iDType
      )
    ) {
      arr = option4.value;
    } else if (
      [
        "中华人民共和国外国人工作许可证（A类）",
        "中华人民共和国外国人工作许可证（B类）",
        "中华人民共和国外国人工作许可证（C类）",
      ].includes(info.value.iDType)
    ) {
      arr = [
        ...option1.value,
        ...option3.value,
        ...option2.value,
        ...option4.value,
      ];
    }
  } else {
    arr = option1.value;
  }

  return arr;
});
//出生国家地区
const csCountryOption = ref([
  ...option1.value,
  ...option3.value,
  ...option2.value,
  ...option4.value,
]);
//其他证件类型
const qtOption = ref([
  {
    value: "外国护照",
    label: "外国护照",
  },
  {
    value: "外国人永久居留身份证（外国人永久居留证）",
    label: "外国人永久居留身份证（外国人永久居留证）",
  },
  {
    value: "中华人民共和国外国人工作许可证（A类）",
    label: "中华人民共和国外国人工作许可证（A类）",
  },
  {
    value: "中华人民共和国外国人工作许可证（B类）",
    label: "中华人民共和国外国人工作许可证（B类）",
  },
  {
    value: "中华人民共和国外国人工作许可证（C类）",
    label: "中华人民共和国外国人工作许可证（C类）",
  },
]);
const qtOption1 = ref([
  {
    value: "中国护照",
    label: "中国护照",
  },
  {
    value: "中华人民共和国港澳居民居住证",
    label: "中华人民共和国港澳居民居住证",
  },
  {
    value: "中华人民共和国台湾居民居住证",
    label: "中华人民共和国台湾居民居住证",
  },
  {
    value: "港澳居民来往内地通行证",
    label: "港澳居民来往内地通行证",
  },
  {
    value: "台湾居民来往大陆通行证",
    label: "台湾居民来往大陆通行证",
  },
]);
const qtIdTypes = computed(() => {
  let arr = [];
  if (info.value.iDType == "中国护照") {
    arr = [qtOption1.value[0]];
  } else if (info.value.iDType == "港澳居民来往内地通行证") {
    arr = [qtOption1.value[1]];
  } else if (info.value.iDType == "台湾居民来往大陆通行证") {
    arr = [qtOption1.value[2]];
  } else if (info.value.iDType == "中华人民共和国港澳居民居住证") {
    arr = [qtOption1.value[3]];
  } else if (info.value.iDType == "中华人民共和国台湾居民居住证") {
    arr = [qtOption1.value[4]];
  } else if (info.value.iDType == "外国护照") {
    arr = qtOption.value;
  } else if (info.value.iDType == "外国人永久居留身份证（外国人永久居留证）") {
    arr = [qtOption.value[0], qtOption.value[1]];
  } else if (
    [
      "中华人民共和国外国人工作许可证（A类）",
      "中华人民共和国外国人工作许可证（B类）",
      "中华人民共和国外国人工作许可证（C类）",
    ].includes(info.value.iDType)
  ) {
    arr = [qtOption.value[0]];
  }
  return arr;
});
//涉税事由option
const ssOption = [
  {
    value: "任职受雇",
    label: "任职受雇",
  },
  {
    value: "提供临时劳务",
    label: "提供临时劳务",
  },
  {
    value: "转让财产",
    label: "转让财产",
  },
  {
    value: "从事投资和经营活动",
    label: "从事投资和经营活动",
  },
  {
    value: "其他",
    label: "其他",
  },
];
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(260);
});
const openDialog = (row) => {
  reset();
  if (row) {
    info.value = Object.assign({}, row);
  }
  dialogFormVisible.value = true;
};
const idnoChange = () => {
  if (info.value.iDNo.length == 18) {
    let nian = info.value.iDNo.substring(6, 10);
    let yue = info.value.iDNo.substring(10, 12);
    let ri = info.value.iDNo.substring(12, 14);
    info.value.birthday = nian + "-" + yue + "-" + ri;
    let sex = info.value.iDNo.substring(16, 17);
    if (sex % 2 == 1) {
      info.value.sex = 1;
    } else {
      info.value.sex = 2;
    }
  }
};
const idTypeChange = () => {
  info.value.country = "";
  info.value.qtIdType = "";
};
const saveadd = () => {
  if (!props.period && proxy.$checkSettleStatus()) return;
  info.value.comId = props.comId * 1;
  info.value.totalStock = Number(info.value.totalStock);
  info.value.personalStock = Number(info.value.personalStock);
  info.value.stockPercent = Number(info.value.stockPercent);
  info.value.childrenEducation = Number(info.value.childrenEducation);
  info.value.continuingEducation = Number(info.value.continuingEducation);
  info.value.housingLoan = Number(info.value.housingLoan);
  info.value.housingRent = Number(info.value.housingRent);
  info.value.support = Number(info.value.support);
  info.value.babyReduction = Number(info.value.babyReduction);
  info.value.personalPension = Number(info.value.personalPension);
  proxy.$refs["dataForm"].validate((valid) => {
    if (valid) {
      saveEmployees([info.value]).then((e) => {
        if (e.data.msg != "success") {
          proxy.$message.error(e.data.msg);
          return;
        }
        if (e.data.msg == "success") {
          let text = props.type1 == "编辑人员" ? "修改成功" : "添加人员成功";
          proxy.$notify({
            title: "成功",
            message: text,
            type: "success",
            duration: 2000,
          });
          dialogFormVisible.value = false;
          emits("success");
        }
      });
    }
  });
};
const reset = () => {
  info.value = {
    iDType: "",
    country: "",
    iDNo: "",
    birthday: "",
    sex: "",
    disability: 2,
    isEmployee: 1,
    stockholder: 2,
    martyred: 2,
    elderly: 2,
    totalStock: 0,
    personalStock: 0,
    stockPercent: 0,
    joinDate: "",
    departureDate: "",
    gsjm0: 0,
    scyjsj: "",
    yjljsj: "",
    sssy: "任职受雇",
    chineseName: "",
    csgj: "",
    qtIdno: "",
    qtIdType: "",
  };
  if (props.from == "inside") {
    info.value.iDType = "居民身份证";
    info.value.country = "中国";
  }
};
defineExpose({
  openDialog,
});
</script>

<style lang="scss" scoped>
.item_one {
  :deep(.el-input__wrapper) {
    width: 200px;
  }
}
.item_one {
  :deep(.el-date-editor) {
    width: 200px;
  }
}
.el-select {
  margin: 0px;
}
.el-form-item {
  margin-right: 0 !important;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
</style>
