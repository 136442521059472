<template>
  <el-dialog
    :close-on-click-modal="false"
    title="离职日期"
    v-model="dialogVisible"
    width="450px"
  >
    <el-form>
      <el-form-item label="离职日期" label-width="120">
        <el-date-picker
          size="small"
          type="date"
          value-format="YYYY-MM-DD"
          v-model="temp.departureDate"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveTime()" size="small"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";
import { saveEmployees } from "@/api/salary";
const { proxy } = getCurrentInstance();
const emit = defineEmits();
const dialogVisible = ref(false);
const temp = ref({});
const init = (item) => {
  temp.value = Object.assign({}, item);
  dialogVisible.value = true;
};
const saveTime = () => {
  if (!temp.value.departureDate) {
    proxy.$message.error("请选择离职日期!");
    return;
  }
  temp.value.status = 2;
  saveEmployees([temp.value]).then((res) => {
    if (res.data.msg == "success") {
      proxy.$qzfMessage("修改成功");
      dialogVisible.value = false;
      emit("success");
    }
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss"></style>
